import React, { useEffect, useState } from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';
import {lcfRoutes, protectedRoutes, publicRoutes, adminRoutes, userRoutes, masterRoutes,teacherRoutes } from 'configs/RoutesConfig';
import AppRoute from './AppRoute';
import userService from 'services/UserService';
import { useDispatch, useSelector } from 'react-redux';
import { addRole } from 'store/slices/authSlice'
import { createBrowserHistory } from 'history';

const Routes = () => {
	const [user, setUser] = useState({ name: '', lname: '', email: '' })
	const token = useSelector(state => state.auth.token);
	const role = useSelector(state => state.auth.role);
	const dispatch = useDispatch()

	const fetchMe = async () => {
		if (token) {
			try {
				let resp = await userService.getMe()
				resp = resp.user
				if (resp.email !== '') {
					setUser(resp)
					dispatch(addRole(resp.role))
				}
			} catch (errors) {
				console.log(errors)
			}
		}
	}
	useEffect(() => {
		if (!user.email) {
			fetchMe()
		}

	}, [])
	return (
		<RouterRoutes>
			{role === "admin" && adminRoutes.map((route, index) => {
				return (
					<Route
						key={route.key + index}
						path={route.path}
						element={
							<AppRoute
								routeKey={route.key}
								component={route.component}
								{...route.meta}
							/>
						}
					/>
				)
			})}
			{role === "lcf" && lcfRoutes.map((route, index) => {
				return (
					<Route
						key={route.key + index}
						path={route.path}
						element={
							<AppRoute
								routeKey={route.key}
								component={route.component}
								{...route.meta}
							/>
						}
					/>
				)
			})}

			{role === "master" && masterRoutes.map((route, index) => {
				return (
					<Route
						key={route.key + index}
						path={route.path}
						element={
							<AppRoute
								routeKey={route.key}
								component={route.component}
								{...route.meta}
							/>
						}
					/>
				)
			})}
				{role === "teacher" && teacherRoutes.map((route, index) => {
				return (
					<Route
						key={route.key + index}
						path={route.path}
						element={
							<AppRoute
								routeKey={route.key}
								component={route.component}
								{...route.meta}
							/>
						}
					/>
				)
			})}
				{protectedRoutes.map(route => {
				return (
					<Route
						key={route.path}
						path={route.path}
						element={
							<AppRoute
								routeKey={route.key}
								component={route.component}
								{...route.meta}
							/>
						}
					/>
				)
			})}
			{publicRoutes.map(route => {
				return (
					<Route
						key={route.path}
						path={route.path}
						element={
							<AppRoute
								routeKey={route.key}
								component={route.component}
								{...route.meta}
							/>
						}
					/>
				)
			})}

{/* {!token && <Route path="*" element={<Navigate to="/" />} />}
{token && <Route path="*" element={<Navigate to="/" />} />} */}
		</RouterRoutes>
	)
}

export default Routes