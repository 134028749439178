import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN,ROLE } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import AuthService from 'services/AuthService';
import { updateCurrentUser } from 'firebase/auth';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	master:null,
	role:localStorage.getItem(ROLE) || null,
	user:localStorage.getItem('USER') || null,
	token: localStorage.getItem(AUTH_TOKEN) || null
}

export const signIn = createAsyncThunk('user/login',async (data, { rejectWithValue }) => {
	const { email, password } = data
	localStorage.removeItem(ROLE);
	localStorage.removeItem('USER');
	localStorage.removeItem('firstLogin')
	try {
		const response = await AuthService.login({email, password})
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		localStorage.setItem('isAuthed', true);
		
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const newUserCreate = createAsyncThunk('user/newregister',async (data, { rejectWithValue }) => {
	const {name,lname, email, password , password_confirm,role,fee,licenseprice,royaltyfee,licensefee,licence,centri,master,lcf,country} = data
	try {
		const response = await AuthService.newUser({name,lname, email, password , password_confirm,role,fee,licenseprice,royaltyfee,licensefee,licence,centri,master,lcf,country})
		
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
 	}
})
export const userUpdate = createAsyncThunk('users/update',async (data, { rejectWithValue }) => {
	const {id,name,lname, email, password , password_confirm,role,active,licence,master,lcf,centri} = data
	try {
		const response = await AuthService.update({id,name,lname, email, password , password_confirm,role,active,licence,master,lcf,centri})
		const token = response.data.token;

		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
 	}
})
export const signUp = createAsyncThunk('user/register',async (data, { rejectWithValue }) => {
	const {name,lname, email, password , password_confirm} = data
	try {
		const response = await AuthService.register({name,lname, email, password , password_confirm})
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
 	}
})

export const signOut = createAsyncThunk('auth/logout',async () => {
    const response = await FirebaseService.signOutRequest()
	localStorage.removeItem(AUTH_TOKEN);
	localStorage.removeItem(ROLE);
	localStorage.removeItem('USER');
	localStorage.removeItem('isAuthed');

	localStorage.removeItem('firstLogin')
    return response.data
})

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (_, { rejectWithValue }) => {
    try {
		const response = await AuthService.loginInOAuth()
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signInWithFacebook = createAsyncThunk('auth/signInWithFacebook', async (_, { rejectWithValue }) => {
    try {
		const response = await AuthService.loginInOAuth()
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		addRole: (state, action) => {
			localStorage.setItem(ROLE, action.payload);

			state.role = action.payload
		},
		addUser: (state, action) => {
			localStorage.setItem('USER', action.payload);

			state.user = action.payload
		},
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			localStorage.removeItem('firstLogin')
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		hideLoading: (state) => {
			state.loading = false
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload.token
			state.role = action.payload.role
			state.user = action.payload.user
			state.master = action.payload.master
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(userUpdate.pending, (state) => {
				state.loading = true
			})
			.addCase(userUpdate.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(userUpdate.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
		 
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				localStorage.removeItem('firstLogin')
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				localStorage.removeItem('firstLogin')
				state.redirect = '/'
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(newUserCreate.pending, (state) => {
				state.loading = true
			})
			.addCase(newUserCreate.fulfilled, (state, action) => {
				state.loading = false
				
			})
			.addCase(newUserCreate.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithGoogle.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithGoogle.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithGoogle.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithFacebook.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithFacebook.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithFacebook.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const { 
	addRole,
	addUser,
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	hideLoading,
	signInSuccess,
} = authSlice.actions

export default authSlice.reducer